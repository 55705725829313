import axios from 'axios';

export async function makeRequest(
  url: any,
  method: any,
  data: any,
  params: any,
  headers: any
) {
  return axios({
    url,
    method,
    data,
    params,
    headers,
  });
}

export async function doRequest(
  url: any,
  method = 'get',
  dataOrParams = {},
  contentType = ''
) {
  const headers = {
    Accept: 'application/json',
    'Content-type': contentType || 'application/json',
    Authorization: `Bearer ${process.env.CMS_TOKEN}`,
    'x-api-key': `${process.env.SECURE_CMS_TOKEN}`,
  };

  const params = method === 'get' ? dataOrParams : {};
  const data = method !== 'get' ? dataOrParams : undefined;
  return makeRequest(url, method, data, params, headers);
}

export async function doDataVaultRequest(
  url: any,
  method = 'get',
  dataOrParams = {},
  contentType = ''
) {
  const headers = {
    Accept: 'application/json',
    'Content-type': contentType || 'application/json',
    'x-api-key': `${process.env.DATA_VAULT_TOKEN}`,
  };

  const params = method === 'get' ? dataOrParams : {};
  const data = method !== 'get' ? dataOrParams : undefined;
  return makeRequest(url, method, data, params, headers);
}

export async function doRequestNew(
  url: any,
  method = 'get',
  dataOrParams = {},
  contentType = ''
) {
  const headers = {
    Accept: 'application/json',
    'Content-type': contentType || 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'origin, content-type, accept',
  };

  const params = method === 'get' ? dataOrParams : {};
  const data = method !== 'get' ? dataOrParams : undefined;
  return makeRequest(url, method, data, params, headers);
}

export async function makeRequestP(url: any, method: any) {
  return axios({
    url,
    method,
  });
}
export async function doRequestNewP(url: any, method = 'get') {
  return makeRequestP(url, method);
}
